<template>
  <div class="page_contain" :class="themeClass">
    <header>
      <div class="wrapper">
        <!-- <h2>{{ orderInfo.accountBank }}</h2> -->
        <h2>{{ orderInfo.id }}</h2>
        <span
          >{{ paymentAmount }}<strong>{{ $t("CurrencyCode") }}</strong></span
        >
      </div>
    </header>

    <div class="information">
      <div class="wrapper">
        <div class="count_down">
          <span style="color: red">{{ $t("CountDownHint") }}</span>
          <span style="color: red">{{ deadline }}</span>
        </div>

        <div class="qrcode_receive" v-if="orderInfo.showQrcode">
          <!-- 
          <h4>{{ $t("ReferenceCode") }}</h4>
          <span>{{ orderInfo.id }}</span> 
          -->
          <img :src="qrCodeUrl" alt="QR Code" />
          <p>{{ $t("QRErrorHint") }}</p>
        </div>

        <div class="account_receive">
          <!-- Account Bank -->
          <div class="detail" v-if="orderInfo.accountBank">
            <h6>{{ $t("OrderInfo.AccountBank") }}</h6>
            <div class="copy_contain">
              <span>{{ orderInfo.accountBank }}</span>
              <button
                class="copy_btn copy_bank"
                @click="copyText('copy_bank', orderInfo.accountBank)"
              >
                {{ $t("Button.Copy") }}
              </button>
            </div>
          </div>

          <!-- Account IFSC -->
          <div class="detail" v-if="orderInfo.accountIfsc">
            <h6>{{ $t("OrderInfo.AccountIFSC") }}</h6>
            <div class="copy_contain">
              <span>{{ orderInfo.accountIfsc }}</span>
              <button
                class="copy_btn copy_ifsc"
                @click="copyText('copy_ifsc', orderInfo.accountIfsc)"
              >
                {{ $t("Button.Copy") }}
              </button>
            </div>
          </div>

          <!-- Account Name -->
          <div class="detail" v-if="orderInfo.accountUser">
            <h6>{{ $t("OrderInfo.AccountName") }}</h6>
            <div class="copy_contain">
              <span>{{ orderInfo.accountUser }}</span>
              <button
                class="copy_btn copy_name"
                @click="copyText('copy_name', orderInfo.accountUser)"
              >
                {{ $t("Button.Copy") }}
              </button>
            </div>
          </div>

          <!-- Account Number -->
          <div class="detail" v-if="orderInfo.accountNo">
            <h6>{{ $t("OrderInfo.AccountNo") }}</h6>
            <div class="copy_contain">
              <span>{{ orderInfo.accountNo }}</span>
              <button
                class="copy_btn copy_number"
                @click="copyText('copy_number', orderInfo.accountNo)"
              >
                {{ $t("Button.Copy") }}
              </button>
            </div>
          </div>

          <!-- Payment Amount -->
          <div class="detail" v-if="orderInfo.amount">
            <h6>{{ $t("OrderInfo.Amount") }}</h6>
            <div class="copy_contain">
              <span>{{ paymentAmount }}</span>
              <button
                class="copy_btn copy_amount"
                @click="copyText('copy_amount', orderInfo.amount)"
              >
                {{ $t("Button.Copy") }}
              </button>
            </div>
          </div>

          <!-- Payment UPI -->
          <div class="detail" v-if="orderInfo.accountUpi">
            <h6>{{ $t("OrderInfo.AccountUpi") }}</h6>
            <div class="copy_contain">
              <span>{{ orderInfo.accountUpi }}</span>
              <button
                class="copy_btn copy_name"
                @click="copyText('copy_name', orderInfo.accountUpi)"
              >
                {{ $t("Button.Copy") }}
              </button>
            </div>
          </div>

          <!-- Send UTR -->
          <!-- <div class="detail" v-if="$i18n.locale === 'ind'"> -->
          <div class="detail" v-if="orderInfo.submitUtr">
            <h6>{{ $t("OrderInfo.UTR") }}</h6>
            <div class="copy_contain">
              <input
                type="text"
                placeholder="Enter UTR / UPI reference ID"
                v-model="UtrCode"
                @input="focusInputUtr"
                @blur="blurInputUtr"
              />
              <button
                class="submit_btn"
                :class="{ disable: UtrCode.length < 12 }"
                @click="verifySendUtr"
              >
                Send
              </button>
            </div>
            <span class="alert_msg" v-if="showUtrAlert">{{
              utrInputAlert
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 提示組件 -->
    <div class="toasts" v-for="(item, index) in toasts" :key="index">
      <toast :message="item.message" />
    </div>

    <!-- 彈窗組建 -->
    <pop-dialog
      v-if="showDialog"
      :locale="$i18n.locale"
      :dialogInfo="dialogInfo"
      @dialogCancel="verifyUtrCancel"
      @dialogSubmit="sendUtr"
      @dialogClose="closeDialog"
      @dialogSure="sureDialog"
    >
    </pop-dialog>
  </div>
</template>

<script>
import axios from "axios";
import QRCode from "qrcode";
import ClipboardJS from "clipboard";
import ToastVue from "@/components/ToastModel.vue";
import DialogVue from "@/components/DialogModel.vue";

export default {
  props: {
    // 语系、地区
    locale: {
      type: String,
      default: "",
    },
    // 订单资讯字串
    orderInfoString: {
      type: String,
      default: "",
    },
  },
  components: {
    toast: ToastVue,
    "pop-dialog": DialogVue,
  },
  data() {
    return {
      orderInfo: {}, // 订单资讯
      qrCodeUrl: "", // QR Code 图片网址
      paymentAmount: 0, // 支付金额
      deadline: "", // 距离订单截止时间
      UtrCode: "", // UTR
      showUtrAlert: false, // 是否显示 UTR 提示
      showDialog: false, // 是否显示弹窗
      dialogInfo: {}, // 弹窗资讯
      utrInputAlert: "", // UTR 提示
      toasts: [], // Toast 讯息
      statusTx: null,
    };
  },
  methods: {
    /**
     * 生成 QR Code
     */
    generateQRCode: function () {
      QRCode.toDataURL(this.orderInfo.accountInfo)
        .then((url) => {
          this.qrCodeUrl = url;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 显示 Toast 讯息
     * @param {String} message 讯息内容
     */
    showToast: function (message) {
      const toast = { message };
      this.toasts.push(toast);

      // 自动在2秒后移除 Toast
      setTimeout(() => {
        this.toasts.shift(); // 从数组中移除第一个 Toast
      }, 2000);
    },
    /**
     * 複製文字
     * @param {String} value 要複製的文字
     */
    copyText: function (elementClass, value) {
      const clipboard = new ClipboardJS("." + elementClass, {
        text: () => {
          // 若是複製金额，则需将金额转换为元: changed
          if (elementClass === "copy_amount") {
            return value.toString();
          } else {
            return value.toString();
          }
        },
      });

      clipboard.on("success", () => {
        this.showToast(this.$t("CopySuccess"));
        clipboard.destroy();
      });

      clipboard.on("error", () => {
        // alert("Copy Failed");
        clipboard.destroy();
      });
    },
    /**
     * 根据不同语系格式化金额
     */
    formatAmount: function () {
      const locale = this.locale; // 语系
      // let amountInCents = this.orderInfo.amount; // 原订单金额, 以分为单位

      // 将金额从分转换为当地货币的单位，如元
      let amount = this.orderInfo.amount;

      switch (locale) {
        case "ind": // 印度
          this.paymentAmount = new Intl.NumberFormat("en-IN", {
            useGroutrng: true,
            minimumFractionDigits: 2,
          }).format(amount);
          break;

        case "vnm": // 越南
          this.paymentAmount = new Intl.NumberFormat("vi-VN", {
            useGroutrng: true,
            minimumFractionDigits: 0,
          }).format(amount);
          break;

        case "chn": // 中国
          this.paymentAmount = new Intl.NumberFormat("zh-CN", {
            useGroutrng: true,
            minimumFractionDigits: 2,
          }).format(amount);
          break;
      }
    },
    /**
     * 设定订单倒数计时
     */
    setOrderCountDown: function () {
      let limit = this.orderInfo.deadline; // 订单截止时间

      // 设置倒计时
      const countDownInterval = setInterval(() => {
        const now = new Date().getTime(); // 当前时间
        const distance = limit - now; // 距离截止时间的时间差

        // 如果时间到，则显示已过期
        if (distance < 0) {
          clearInterval(countDownInterval);
          this.deadline = "00:00"; // 倒计时结束后显示00:00
          return;
        }

        // 计算时间
        const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // 格式化时间字符串
        let timeString = "";
        if (hours > 0) {
          timeString = `${String(hours).padStart(2, "0")}:${String(
            minutes
          ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
        } else {
          timeString = `${String(minutes).padStart(2, "0")}:${String(
            seconds
          ).padStart(2, "0")}`;
        }

        // 显示时间
        this.deadline = timeString;
      }, 1000);
    },
    /**
     * 聚焦 UTR 输入框時 檢查輸入內容
     */
    focusInputUtr: function () {
      const utrInput = this.UtrCode;

      // 若 UTR 输入框为空
      if (utrInput.length == 0) {
        this.showUtrAlert = true;
        this.utrInputAlert = this.$t("Alert.UTR.Empty");
        return;
      }

      // 若 UTR 长度不足
      if (utrInput.length < 12) {
        this.showUtrAlert = true;
        this.utrInputAlert = this.$t("Alert.UTR.InsufficientLength");
        return;
      }

      // 若 UTR 长度足够
      if (utrInput.length >= 12) {
        this.showUtrAlert = false;
        this.utrInputAlert = "";
      }
    },
    /**
     * 离开 UTR 输入框时 检查输入内容
     */
    blurInputUtr: function () {
      const utrInput = this.UtrCode;

      // 若 UTR 输入框为空
      if (utrInput.length == 0 || utrInput.length >= 12) {
        this.showUtrAlert = false;
        this.utrInputAlert = "";
        return;
      }

      // 若 UTR 长度不足
      if (utrInput.length > 0 && utrInput.length < 12) {
        this.showUtrAlert = true;
        this.utrInputAlert = this.$t("Alert.UTR.InsufficientLength");
        return;
      }
    },
    /**
     * 验证发送 UTR
     */
    verifySendUtr: function () {
      const utrInput = this.UtrCode;

      if (utrInput.length == 0 || utrInput.length < 12) {
        return;
      }

      // 開啟確認是否發送 UTR 的彈窗
      this.showDialog = true;
      this.dialogInfo = {
        type: "question",
        title: this.$t("Dialog.UTR.Submit.Title"),
        msg: this.$t("Dialog.UTR.Submit.Message") + ` ${utrInput}?`,
      };
    },
    /**
     * 取消 UTR 发送
     */
    verifyUtrCancel: function () {
      this.showDialog = false;
      this.dialogInfo = {};
    },
    /**
     * 发送 UTR
     */
    sendUtr: function () {
      this.dialogInfo = {};
      this.showDialog = false;

      const domain = this.orderInfo.domain;
      const id = this.orderInfo.id;
      const utr = this.UtrCode;

      axios
        .post(`${domain}/sys/zapi/submitUtr?id=${id}&utr=${utr}`)
        .then((res) => {
          if (res.status == 200) {
            this.dialogInfo = {
              type: "success",
              title: this.$t("Dialog.UTR.Success.Title"),
              msg: this.$t("Dialog.UTR.Success.Message"),
            };
            this.showDialog = true;
          }
        })
        .catch((err) => {
          console.error(err);
          this.dialogInfo = {
            type: "fail",
            title: this.$t("Dialog.UTR.Fail.Title"),
            msg: this.$t("Dialog.UTR.Fail.Message"),
          };
          this.showDialog = true;
        });
    },

    /**
     * 关闭弹窗
     */
    closeDialog: function () {
      this.showDialog = false;
      this.dialogInfo = {};
    },

    /**
     * 确认
     */
    sureDialog: function () {
      console.log("sureDialog clicked")
      const domain = this.orderInfo.domain;
      const id = this.orderInfo.id;
      this.showDialog = false;
      this.statusTx = setInterval(() => {
        axios
          .get(`${domain}/sys/zapi/chargeStatus?id=${id}`)
          .then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              console.log(this.orderInfo);
              if (res.data.data === 1) {
                location.href = this.orderInfo.callbackUrl;
                clearInterval(this.statusTx);
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }, 10000);
    },

    /**
     * 初始化
     */
    init: function () {
      this.$i18n.locale = this.locale; // 设定语系
      this.themeClass = `${this.locale}-theme`; // 设定主题样式
      this.orderInfo = JSON.parse(this.orderInfoString); // 设定订单资讯
      if (this.orderInfo.utr) {
        this.UtrCode = this.orderInfo.utr;
        // todo
      }
      this.formatAmount(); // 格式化金额
      this.setOrderCountDown(); // 设定倒数计时
    },
  },
  created() {
    this.init();
  },
  mounted() {
    this.generateQRCode();
  },
  watch: {
    deadline: function () {
      if (this.deadline === "00:00") {
        this.$router.push({
          name: "ErrorView",
          params: { locale: this.locale, type: "expired" },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";

* {
  @include base-style;
}

.page_contain {
  display: block;
  width: 100%;

  header {
    display: block;
    width: 100%;
    height: 0.8rem;
    background-color: var(--theme-color);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mobile-wrapper;

      h2 {
        color: $font-white;
        font-size: 0.4rem;
        line-height: 0.8rem;
      }

      span {
        color: $font-white;
        font-size: 0.4rem;
        line-height: 0.8rem;

        strong {
          color: $font-white;
          font-size: 0.4rem;
          margin-left: 0.15rem;
        }
      }
    }
  }

  .information {
    display: block;
    width: 100%;
    margin: 0.15rem 0;
    padding: 0.8rem 0.15rem 0;

    .wrapper {
      @include card-box;

      .count_down {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 0.28rem;
          font-weight: 700;
          line-height: 0.4rem;
        }

        span:first-of-type {
          color: var(--theme-color);
          font-size: 0.3rem;
        }
      }

      .qrcode_receive {
        display: block;
        width: 100%;

        h4,
        span {
          display: block;
          width: 100%;
          color: $font-grey;
          font-size: 0.28rem;
          text-align: center;
          line-height: 1.2rem;
        }

        span {
          line-height: 0.4rem;
        }

        img {
          display: block;
          width: 350px;
          height: 350px;
          margin: 0 auto;
        }

        p {
          display: block;
          color: $font-grey;
          font-size: 0.28rem;
          text-align: left;
          line-height: 0.4rem;
          margin-bottom: 0.2rem;
        }
      }

      .account_receive {
        display: block;
        width: 100%;
        padding: 0.1rem 0;

        .detail {
          display: block;
          width: 100%;
          margin-bottom: 0.25rem;

          h6 {
            display: block;
            color: $font-black;
            font-size: 0.28rem;
            font-weight: 700;
            line-height: 0.4rem;
            text-align: left;
          }

          .copy_contain {
            display: flex;
            border: 1px solid $border-color;
            border-radius: $border-radius;
            align-items: center;
            justify-content: space-between;
            margin: 0.1rem 0;
            overflow: hidden;

            span,
            input {
              display: block;
              width: 70%;
              background: $input-background;
              font-size: 0.28rem;
              text-align: left;
              padding-left: 0.15rem;
              line-height: 0.6rem;
            }

            input {
              background: transparent;
              border: none;
              padding: 0 0 0 0.15rem;
            }

            input::placeholder {
              color: $font-grey;
              font-size: 0.26rem;
              line-height: 0.4rem;
            }

            button {
              display: block;
              width: 30%;
              background-color: var(--theme-color);
              border: none;
              color: #fff;
              font-size: 0.28rem;
              line-height: 0.6rem;
              margin: 0;
              padding: 0;
            }

            .submit_btn.disable {
              background-color: $btn-disabled;
            }
          }

          span.alert_msg {
            display: block;
            color: $font-red;
            font-size: 0.24rem;
            text-align: left;
            line-height: 0.4rem;
          }
        }
      }
    }
  }
}
</style>
